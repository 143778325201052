import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
 
import IconButton from "@material-ui/core/IconButton";
import MobileStepper from "@material-ui/core/MobileStepper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";
import ReactSelect from "../../common/ReactSelect";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import { ApplicationRecord, ApplicationRecordStatus, SandhillPermit, SandhillCulvert, SandhillAttachment } from "../../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 500,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.SandhillPermit.filter((p) => p.hash === hash).toModelArray()[0];
    }
);

class Sandhill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            culvertDialogOpen: false,
            culvertEditing: null,
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: window.performance.now(),
            entryError: false,
            projectType: [],
            selectLegal: false,
            tempCulverts: [],
            culvertFormKey:null,
            dialogLoading:false,
        };
    };

    componentDidMount() {
        const { match, ormSandhillPermitLoadDetailChild, ormSandhillAttachmentLoadDetailChild, ormSandhillCulvertLoadDetailChild } = this.props;
        var component = this;
        if (match.params && match.params.hash) {
            ormSandhillPermitLoadDetailChild(match.params.hash, function (wtf) {
                component.setState({ mainFormKey: window.performance.now(), projectType:wtf[0].project_types });
            });
            ormSandhillCulvertLoadDetailChild(match.params.hash, function (data) {
                component.setState({ tempCulverts: data });
            });
            ormSandhillAttachmentLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_attachments: data });
            });
        }
    };

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        var valObj = {
            appl_first_name: isRequired(values.appl_first_name),
            appl_last_name: isRequired(values.appl_last_name),
            appl_phone: isRequired(values.appl_phone),
            project_types: isRequired(values.project_types),
            county: isRequired(values.county),
            project_description: isRequired(values.project_description),
            project_township: isRequired(values.project_township),
            section: isRequired(values.section),
            understand1: isRequired(values.understand1),
            geometry: isRequired(values.geometry), 
        };

        var alerts = "";
        var ai = 6;
        if (valObj["appl_first_name"] === "Required") {
            alerts += "Landowner First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["appl_last_name"] === "Required") {
            alerts += "Landowner Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["appl_phone"] === "Required") {
            alerts += "Landowner Phone  (Step 2)\n";
            ai = 1;
        }
        if (valObj["county"] === "Required") {
            alerts += "County (Step 3)\n";
            ai = 2;
        }
        if (valObj["project_township"] === "Required") {
            alerts += "Township/City Name (Step 3)\n";
            ai = 2;
        }
        if (valObj["geometry"] === "Required") {
            alerts += "Map Project Location Required (Step 3)\n";
            ai = 2;
        }
        if (valObj["section"] === "Required") {
            alerts += "Section (Step 3)\n";
            ai = 2;
        }
        if (valObj["project_types"] === "Required") {
            alerts += "Project types (Step 4)\n";
            ai = 3;
        }
        if (valObj["project_description"] === "Required") {
            alerts += "Project description (Step 4)\n";
            ai = 3;
        }
        if (valObj["understand1"] === "Required") {
            alerts += "Understand Agreement (Step 7)\n";
            ai = 6;
        }
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorAttachment = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };

    submitApplication = (values) => {
        const {
            application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormSandhillPermitCreate,
            ormSandhillCulvertCreate,
            ormSandhillAttachmentCreate, 
        } = this.props;
        const { tempCulverts, temp_attachments } = this.state;

        const _this = this;
        this.setState({ loading: true });

        if (application) {
            // Editing
        } else {
            // New
            ormApplicationRecordCreate({
                application: 12,
            }).then((ar) => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Received",
                });
                ormSandhillPermitCreate({
                    applicationrecord: ar.id,
                    ...values,
                }).then((rpb) => {
                    if (rpb && rpb.error) {
                        this.setState({ entryError: true, loading: false });
                        fetch("/error/post", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                error: JSON.stringify(values),
                                error_info: rpb.error.toString(),
                            }),
                        });
                    } else {
                        var i = 0;
                        var j = 0;

                        tempCulverts.forEach(function (tc) {
                            i++;
                            ormSandhillCulvertCreate({
                                sandhillpermit_id: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        temp_attachments.forEach(function (tc) {
                            i++;
                            ormSandhillAttachmentCreate({
                                sandhillpermit_id: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    }
                });
            });
        }
    };

    addCulvert = (values) => {
        var { culvertEditing, tempCulverts } = this.state;

        if (!culvertEditing) {
            tempCulverts.push(values);
            this.setState({ tempCulverts: tempCulverts });
        } else {
            var temp = tempCulverts.filter((tc) => tc !== culvertEditing);
            temp.push(values);
            this.setState({ tempCulverts: temp });
        }

        this.setState({ culvertDialogOpen: false });
    };

    errorValidatorCulvert = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
        };

        return valObj;
    };

    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep <= 5) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    };

    //this is triggered from basemapcontrolapplication.js which will fill in the below 4 fields
    setDrawingFeature = (geom) =>{
        if(geom) {
            this.formApi.setValue('geometry', geom);
            if(geom){//so in this case lets get the 4 service fields now and update on the UI
                //county/section
                fetch(`https://services7.arcgis.com/QuQo9lkbijeKky8S/ArcGIS/rest/services/basemap/FeatureServer/2/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
                    &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {
                    method : "GET"
    
                })
                .then(resp=> resp.json())
                .then(data=>{
                    if(data.features.length === 0){
                    // this.setState({outOfDistrict:true})?
                        console.log("out of district")
                    }else{
                        let res = data.features[0].attributes
                        this.formApi.setValue('county', res.COUN_LC) //CTY_NAME 
                        this.formApi.setValue('section', res.SECT) //plss twp  
                    }
                })
                //township
                fetch(`https://services7.arcgis.com/QuQo9lkbijeKky8S/ArcGIS/rest/services/basemap/FeatureServer/3/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
                  &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {
                  method : "GET"
  
                })
                .then(resp=> resp.json())
                .then(data=>{
                    if(data.features.length === 0){
                    // this.setState({outOfDistrict:true})?
                        console.log("out of district")
                    }else{
                        let res = data.features[0].attributes 
                        this.formApi.setValue('project_township',res.Name) //township/municipality 

                    }
                })
            }
        } else {
            this.formApi.setValue('geometry', null);
        }
    };

    render() {
        const { classes, application, mediaQuery } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            entryError, 
            order,
            orderBy,
            culvertDialogOpen,
            culvertEditing,
            formKey, 
            projectType,
            selectLegal,
            tempCulverts,
            dialogLoading,
        } = this.state;

        const steps = [
            "Instructions",
            "Applicant Information",
            "Project Location",
            "Project Type",
            "Project Type Details",
            "Document Upload",
            "Landowner Acknowledgement and Submittal",
        ];

        console.log(mediaQuery)

        //this has all parameters for the basemapcontrolapplication
        var layers = [{attribution: "", city_id: 62,city_label: "SHWD",id:212, is_basemap: false,
            layer_type: "Web Map", layernumbers: "0", layerurl: null,
            name: "BASEMAP",on_by_default: true,opacity: 1,order: 0,proxyurl: "",show_popup: false, subdomains: "",
            tileddynamic: "",token: "",webmap_id: "23884ede148441588286f734c79c5d0d", wmslayers: "", x: -96.280332, y: 47.53607,
            geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:10.5 }]
        
        // const alltheRefs = {}
        var is_read_only = false;
        if (
            application &&
            application.applicationrecord &&
            (application.applicationrecord.status !== ""
        ))
            is_read_only = true;

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];

        var culvertHeader = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Culvert Name or ID", allowSort: true },
            { id: "e_size", numeric: false, label: "Existing culvert size", allowSort: true },
            { id: "e_length", numeric: false, label: "Existing culvert length", allowSort: true },
            { id: "e_elevation", numeric: false, label: "Existing culvert elevation", allowSort: true },
            { id: "p_size", numeric: false, label: "Proposed culvert size", allowSort: true },
            { id: "p_length", numeric: false, label: "Proposed culvert length", allowSort: true },
            { id: "p_elevation", numeric: false, label: "Proposed culvert elevation", allowSort: true },
            { id: "additional", numeric: false, label: "Additional information about culvert", allowSort: true },
        ]
        
        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the permit application has been submitted successfully. A copy of the permit application
                                has been emailed to the primary property owner's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This permit application was 'not' successfully saved. Please contact the permit 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (activeStep === "PRINT")
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>Permit Printed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );

        //based on county selection we update the other select townships
        //need reactform to be set as pure
        //TOOK OUT TO LOOK TO SEE WHERE BUDGET IS FIRST WITH BRIAN
        // var township = []
        // if(county === 'Becker'){
        //     township = MAKE_OPTIONS(["2","3","2","3"])     
        // }else if (county === 'Clay'){
        //     township = this.MAKE_OPTIONS(["2","3","2","5"])
        // }else if (county === 'Wilkin'){
        //     township = this.MAKE_OPTIONS(["2","3","2","6"])
        // }else if (county === 'Otter Tail'){
        //     township = this.MAKE_OPTIONS(["2","3", "2","7"])
        // } 

        // based on project type will dictate the attachment types they have to choose from (well took away as customer didnt want detailed)
        // but it still determines which help outputs are seen in the attachment step
        var attachmentTypes = []
        if (projectType.length > 0){    
            let tiling = ['Tiling Site Map','Tiling Downstream Landowner Notification']
            let surface = ['Surface Drainage Site Map','Surface Drainage Downstream Landowner Notification']
            let culvert = ['Culvert Site Map']
            let bridge = ['Bridge Site Map','Bridge Project Engineers Report','Bridge Detailed Project Construction Plans']
            let wet = ['Wetland/Water Project Site Map','Wetland/Water Project Detailed Construction Plans']
            let road = ['Road Grading detailed project construction plans','Road Grading site plan']
            let dike = ['Dike site plan','Dike detailed construction plans']
            let channel = ['Channel detailed design plans','Channel site map']
            let land= ['Land Development project detailed construction plans','Land Development project site map']
            let stormwater = ['Stormwater Report showing modeling results of pond design','Stormwater Pollution Prevention Plan (SWPPP)']
            let utility = ['Utility project detailed project plans','Utility project site map']
            let other = ['Legacy File','Other']
            if(projectType.find(t=>t.includes("Tiling")) && !attachmentTypes.find(a=> a.includes("Tiling")))attachmentTypes = [...attachmentTypes, ...tiling] 
            if(projectType.find(t=>t.includes("Surface")) && !attachmentTypes.find(a=> a.includes("Surface")))attachmentTypes = [...attachmentTypes, ...surface]
            if(projectType.find(t=>t.includes("Culvert")) && !attachmentTypes.find(a=> a.includes("Culvert")))attachmentTypes = [...attachmentTypes, ...culvert] 
            if(projectType.find(t=>t.includes("Bridge")) && !attachmentTypes.find(a=> a.includes("Bridge")))attachmentTypes = [...attachmentTypes, ...bridge] 
            if(projectType.find(t=>t.includes("Wetland")) && !attachmentTypes.find(a=> a.includes("Wetland")))attachmentTypes = [...attachmentTypes, ...wet] 
            if(projectType.find(t=>t.includes("Road")) && !attachmentTypes.find(a=> a.includes("Road")))attachmentTypes = [...attachmentTypes, ...road] 
            if(projectType.find(t=>t.includes("Dike")) && !attachmentTypes.find(a=> a.includes("Dike")))attachmentTypes = [...attachmentTypes, ...dike] 
            if(projectType.find(t=>t.includes("Channel")) && !attachmentTypes.find(a=> a.includes("Channel")))attachmentTypes = [...attachmentTypes, ...channel] 
            if(projectType.find(t=>t.includes("Land")) && !attachmentTypes.find(a=> a.includes("Land")))attachmentTypes = [...attachmentTypes, ...land] 
            if(projectType.find(t=>t.includes("Stormwater")) && !attachmentTypes.find(a=> a.includes("Stormwater")))attachmentTypes = [...attachmentTypes, ...stormwater]  
            if(projectType.find(t=>t.includes("Utility")) && !attachmentTypes.find(a=> a.includes("Utility")))attachmentTypes = [...attachmentTypes, ...utility]  
            if(!attachmentTypes.find(a=> a.includes("Legacy")))attachmentTypes = [...attachmentTypes, ...other]  
        }

        return (
            <AppContainerApplication
                city_id={"62"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={application}
                    key={mainFormKey}
                    pure={false}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values) => this.submitApplication(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={2} style={{ padding: !mediaQuery ? "0px 16px" : "0px 24px" }}>
                                {application && application.applicationrecord && application.applicationrecord.status && (
                                    <Grid item xs={12} style={{backgroundColor: "rgb(163, 163, 163)", margin: "-8px 0"}} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Permit Status: {application && application.applicationrecord.status}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} className={classes.centerAlign}>
                                    <Typography variant="h6">
                                        Permit Application Form {application && application.permit_number && "#" + application.permit_number}
                                    </Typography>
                                    <Typography>
                                        <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be
                                        saved so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption">
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12}  >
                                        {!mediaQuery ? (
                                            <Typography variant="subtitle1" style={{fontWeight: "bold", textAlign:"center"}}>
                                                <MobileStepper
                                                    variant="text"
                                                    steps={steps.length + " - " + steps[activeStep]}
                                                    position="static"
                                                    activeStep={activeStep}
                                                    style={{backgroundColor: "rgb(240, 240, 240)"}}
                                                    nextButton={
                                                        <Button
                                                        size="small"
                                                        onClick={() => this.handleNext()}
                                                        disabled={is_read_only && activeStep === 5}
                                                        >
                                                        {activeStep === 6 ? "Submit" : "Next"} <KeyboardArrowRight />
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button size="small" onClick={() => this.setState({ activeStep: activeStep - 1 })} disabled={activeStep === 0}>
                                                            <KeyboardArrowLeft /> Back
                                                        </Button>
                                                    }
                                                />
                                            </Typography>
                                        ) : (
                                            <Stepper style={{ padding: "8px 0 0 0" }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        )}
                                    </Grid>
                                )}
                                {activeStep === 0 && (
                                    <Grid  item direction="row"  justifyContent="center" alignItems="center">
                                        <Typography variant="subtitle1">
                                            <b>1. <u>Preparation</u></b>
                                        </Typography>
                                        <Typography gutterBottom style={{marginTop: -8}}>
                                            <ul>
                                                <li>
                                                    As you work through the online application you will not be able to save your progress for
                                                    submittal at a later time.
                                                </li>
                                                <li>
                                                    Please have all the required information on hand to ensure you are able to submit a
                                                    complete application.
                                                </li>
                                                <li>
                                                    For guidance about the required information, please see the{" "}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="http://www.sandhillwatershed.org/Rules.html"
                                                        >
                                                        SHRWD Rules
                                                    </a>.
                                                </li>
                                            </ul>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <b>2. <u>Submit Application</u></b>
                                        </Typography>
                                        <Typography gutterBottom style={{marginTop: -8}}>
                                            <ul>
                                                <li>
                                                    Once you have compiled the required information, complete the permit application and
                                                    submit. Again, your progress will not be saved for submittal at a later time.
                                                </li>
                                            </ul>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <b>3. <u>Application Review</u></b>
                                        </Typography>
                                        <Typography gutterBottom style={{marginTop: -8}}>
                                            <ul>
                                                <li>
                                                    Once your application has been submitted, SHRWD staff will notify you within 15 days if more information is required.
                                                </li>
                                                <li>
                                                    Once all required information has been received, application will be brought to SHRWD Board for approval.
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Grid>
                                )}
                                {activeStep === 1 && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Typography>
                                            Please add contacts associated with the application. In situations where the work is to be performed in or on property not owned by a private citizen, or if the property is subject 
                                            to an easement or other right to control the property, the applicant must be the person, entity, governmental subdivision, or agency with authority over the property in question. 
                                            Such an applicant may be required to provide the District with proof of its authority to control the property.
                                        </Typography>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Landowner / Responsible Legal Party
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="appl_first_name"
                                                            label="First Name *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="appl_last_name"
                                                            label="Last Name *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="appl_affiliation"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Affiliation"
                                                                    helpText="Please list government unit or private entity that 
                                                                    is applying on behalf of the landowner, if applicable.
                                                                    "
                                                                />
                                                            }
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="appl_email" label="Email" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="appl_phone" label="Phone *" fullWidth usePhoneNumberFormat />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="appl_mailing_address" label="Address" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="appl_mailing_address2" label="Address 2" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="appl_city" label="City" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="appl_state"
                                                            label="State"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="appl_zip" label="Zip" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Renter (if applicable)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="landowner_first_name"
                                                            label="First Name"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="landowner_last_name"
                                                            label="Last Name"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="landowner_email"
                                                            label="Email"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="landowner_phone"
                                                            label="Phone"
                                                            fullWidth
                                                            usePhoneNumberFormat
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="landowner_mailing_address" label="Address" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="landowner_mailing_address2" label="Address 2" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="landowner_city" label="City" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="landowner_state"
                                                            label="State"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="landowner_zip" label="Zip" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <>
                                        <div style={{ height: 50 }}></div>
                                        <Grid item direction="row"  justifyContent="center" alignItems="center">
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    </>
                                )}
                                {activeStep === 2 && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Instructions:</b> 
                                        </Typography>
                                        <Typography gutterBottom>
                                            Step 1: Use the + or - buttons to zoom the map to your permit locations. Alternatively you can type an address or road intersection into the search box to zoom the map to the general area of your permit.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Step 2: Click on the Mapping Tools button labeled "Add Location". This will change your mouse cursor to a blue dot. Click on the map to place your location of your permit. For linear projects place it on the approximate mid-point of the project. For other projects place the point on the best place that represents the location of your project.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Step 3: After you place the point on the map, the Project Location fields will automatically populate. Verify those are the correct information. 
                                        </Typography>
                                        <Typography gutterBottom>
                                            Step 4: If you need to modify the location of the point location on the map, Click Delete Location button and repeat Step 2. Otherwise click the Next button to proceed to the Project Type step. 
                                        </Typography> 
                                        <BaseMapControlApplication
                                            geometry={(application && application.geometry) ? application.geometry : (this.formApi && this.formApi.getValue('geometry')) ? this.formApi.getValue('geometry') : null}
                                            disableSearch={(application && application.geometry) ? application.geometry : (this.formApi && this.formApi.getValue('geometry')) ? this.formApi.getValue('geometry') : null}
                                            viewOnly
                                            application={application}
                                            setDialogLoading={this.setDialogLoading} 
                                            setDrawingFeature={this.setDrawingFeature}
                                            layers={layers}
                                            cityID={"62"}  
                                        /> 
                                        {dialogLoading && ( 
                                            <CircularProgress
                                                size={72}
                                                style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                                            /> 
                                        )}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    <b>Project Location (County, Township and Section is required)</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="county"
                                                    label="County *"
                                                    options={MAKE_OPTIONS(["Mahnomen","Norman","Polk"])}
                                                    fullWidth
                                                    eventHandle={(e) => {
                                                        //FOR UPDATING BELOW FIELD BASED ON COUNTY WORKS--BUT NOT SURE WE WILL PUT IN DUE TO WORKING ON OTHER THINGS FIRST
                                                        // if(e === 'Becker'){
                                                        //     this.setState({county: "Becker"})
                                                        //     formApi.setValue('project_city','') //dumb but needs to be somethign set differently foreach elseif below for selection to update the list on the UI to the user--else reactforms doesnt update properly until the second selection
                                                        // }else if (e === 'Clay'){
                                                        //      this.setState({county: "Clay"})     
                                                        //      formApi.setValue('project_city',' ')
                                                        // }else if (e === 'Wilkin'){
                                                        //     this.setState({county: "Wilkin"})     
                                                        //     formApi.setValue('project_city','  ')
                                                        // }else if (e === 'Otter Tail'){
                                                        //     this.setState({county: "Otter Tail"})     
                                                        //     formApi.setValue('project_city','   ')
                                                        // }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="project_township"
                                                    label="Township/City Name *"
                                                    // options={township}
                                                    options={MAKE_OPTIONS(["Bear Park","Bejou","Brandsvold","Garden","Garfield","Godfrey","Gregory","Hammond","Heier","Hubbard",
                                                    "King","Knute","Liberty","Onstad","Reis","Rosebud","Russia","Scandia","Sletten","Spring Creek","Sundal","Vineland","Winger",
                                                    "Woodside","City of Beltrami","City of Climax","City of Fertile","City of Fosston","City of Nielsville","City of Winger"])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="section"
                                                    label="Section *"
                                                    options={MAKE_OPTIONS([
                                                        "1",
                                                        "2",
                                                        "3",
                                                        "4",
                                                        "5",
                                                        "6",
                                                        "7",
                                                        "8",
                                                        "9",
                                                        "10",
                                                        "11",
                                                        "12",
                                                        "13",
                                                        "14",
                                                        "15",
                                                        "16",
                                                        "17",
                                                        "18",
                                                        "19",
                                                        "20",
                                                        "21",
                                                        "22",
                                                        "23",
                                                        "24",
                                                        "25",
                                                        "26",
                                                        "27",
                                                        "28",
                                                        "29",
                                                        "30",
                                                        "31",
                                                        "32",
                                                        "33",
                                                        "34",
                                                        "35",
                                                        "36",
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="quarter_section"
                                                    label="Primary Quarter Section"
                                                    options={MAKE_OPTIONS(["NW", "NE", "SW", "SE"])}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {activeStep === 3 && (
                                    <Grid iitem direction="row"  justifyContent="center" alignItems="center">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="project_types"
                                                    label="Select Project Types: (Check all that apply)"
                                                    required
                                                    row={true}
                                                    disabled={is_read_only}
                                                    eventHandle={(e) => {
                                                        // if (e.includes("Other")) {
                                                        //     this.setState({ display_project_type_other: true });
                                                        // } else {
                                                        //     this.setState({ display_project_type_other: false });
                                                        // }
                                                        this.setState({projectType:e})
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "Tiling","Surface Drainage (New Ditch,Improvement,or Maintenance/Cleaning)","Culvert Installation / Removal / Modification","Bridge Installation / Removal / Modification",
                                                        "Wetland Restoration / Water Retention Related Structures / Water and Sediment Control Basins","Dike / Levee",
                                                        "Channel Stabilization / Restoration / Erosion Control / Shoreland Projects","Land Development","Utility Installations"
                                                    ])}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="project_description"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Describe in detail the work to be performed *:"
                                                            helpText="Applicant must provide a detailed project description. Application will be considered <u>incomplete</u> until adequate information is provided."
                                                        />
                                                    }
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                /> 
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="why_neccessary"
                                                    label="Why is the work necessary? Explain water related issue or problem being solved." 
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                /> 
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>  
                                                <RadioGroup
                                                    field="area_inundated"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    options={[
                                                        { label: "Yes", value: "Yes" },
                                                        { label: "No", value: "No" },
                                                    ]}
                                                    alignment={true}
                                                    label=" Will the project, including any area inundated as a result of the project, be located entirely on land owned by the applicant? "
                                                    // eventHandle={e=>{
                                                    //     this.setState({selectLegal:e === "Yes"? true : false})
                                                    // }}
                                                />
                                            </Grid> 
                                            <Grid item xs={12} md={6} xl={4}>  
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="project_sponsor"
                                                    label="Project sponsor (MnDOT/County/City/US Fish & Wildlife Service, etc.), if applicable"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>  
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="contractor"
                                                    label="Contractor, if applicable"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>  
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="start_date"
                                                    label="Anticipated construction start date "
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>  
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="who_responsible"
                                                    label=" Who will be responsible for the operation and/or maintenance of the project?"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length === 0 && (
                                    <Grid item  xs={12} >
                                        <Typography variant="subtitle1" gutterBottom style={{textAlign: "center", textDecoration: "underline",fontWeight: "bold"}}>
                                            No Project Types have been selected. Refer to Step 4 and select a 'Project Type'
                                        </Typography>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Tiling") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Tiling
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="til_rec_water"
                                                            label="Describe outlet or receiving waters"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="til_land_ass"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                                { label: "Unsure", value: "Unsure" },
                                                            ]}
                                                            alignment={true}
                                                            label="Land is assessed to legal ditch system"
                                                            eventHandle={e=>{
                                                                this.setState({selectLegal:e === "Yes"? true : false})
                                                            }}
                                                        />
                                                    </Grid> 
                                                    {selectLegal && (
                                                        <Grid item xs={12} md={6} xl={4}>
                                                            <Select
                                                                // disabled={selectLegal }
                                                                field="til_legal_ditch"
                                                                label="Select legal ditch system"
                                                                options={MAKE_OPTIONS(["Project 3 – Onstad-Liberty Ditch",
                                                                "Project 5",
                                                                "Project 11 – Beltrami Flood Control and Diversion Project",
                                                                "Project 12 – Improvement and Consolidation of Polk County Ditches No. 98 and 148",
                                                                "Project 13 – Reis-Scandia Twp Ditch",
                                                                "Project 17",
                                                                "Project 20",
                                                                "Project 24",
                                                                "Project 27 – Union-Sarah Lakes Outlet",
                                                                "Project 32 – Polk County Ditch 80 and Laterals 1 & 2",
                                                                "Sand Hill Ditch",
                                                                "Polk Co Ditch 9",
                                                                "Polk Co Ditch 119"])}
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                    )}
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="til_olet"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Tile outlets across property not owned by landowner or applicant"
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="project_dname" label="Project Designer Name" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="project_dphone" label="Project Designer Phone" fullWidth usePhoneNumberFormat/>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="project_demail" label="Project Designer Email" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="project_iname" label="Project Installer Name" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="project_iphone" label="Project Installer Phone" fullWidth usePhoneNumberFormat/>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="project_iemail" label="Project Installer Email" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="addinfo" label="Additional Information about Tiling Project" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Surface Drainage (New Ditch or Improvement)" || t==="Surface Drainage (New Ditch,Improvement,or Maintenance/Cleaning)") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Surface Drainage (New Ditch, Improvement, or Maintenance/Cleaning)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="sur_drainage"
                                                            label="Surface Drainage Project involves:"
                                                            options={MAKE_OPTIONS(["Construction of new ditch","Improvement of existing ditch"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_depth"
                                                            label="Existing ditch depth (max 50)"
                                                            fullWidth
                                                            units="feet"
                                                            useNumberFormat3
                                                            maxValue={50}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_width"
                                                            label="Existing ditch width (max 1000)"
                                                            fullWidth
                                                            units="feet"
                                                            useNumberFormat3
                                                            maxValue={1000}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="sur_slopes"
                                                            label="Existing ditch side slopes"
                                                            options={MAKE_OPTIONS(["3H:1V","4H:1V","5H:1V","6H:1V"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_pdepth"
                                                            label="Proposed ditch depth (max 50)"
                                                            fullWidth
                                                            units="feet"
                                                            useNumberFormat3
                                                            maxValue={50}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_pwidth"
                                                            label="Proposed ditch width (max 1000)"
                                                            fullWidth
                                                            units="feet"
                                                            useNumberFormat3
                                                            maxValue={1000}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="sur_pslopes"
                                                            label="Proposed ditch side slopes"
                                                            options={MAKE_OPTIONS(["3H:1V","4H:1V","5H:1V","6H:1V"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_how_grade"
                                                            label="How will grade-line of ditch be established?"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_spoil"
                                                            label="Where will spoil be placed?"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="sur_outlet"
                                                            label="Outlet is:"
                                                            options={MAKE_OPTIONS(["Natural Stream / River","Roadside Ditch","Legal Ditch","Applicants Field","Other"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="sur_outlet_other"
                                                            label="If is Outlet Other, Explain"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="sur_ditch_outlet"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Ditch outlets across property not owned by landowner or applicant"
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="sur_land_ass"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                                { label: "Unsure", value: "Unsure" },
                                                            ]}
                                                            alignment={true}
                                                            label="Land is assessed to legal ditch system"
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="sur_legal_ditch"
                                                            label="Legal ditch system"
                                                            options={MAKE_OPTIONS(["Project 3 – Onstad-Liberty Ditch",
                                                            "Project 5",
                                                            "Project 11 – Beltrami Flood Control and Diversion Project",
                                                            "Project 12 – Improvement and Consolidation of Polk County Ditches No. 98 and 148",
                                                            "Project 13 – Reis-Scandia Twp Ditch",
                                                            "Project 17",
                                                            "Project 20",
                                                            "Project 24",
                                                            "Project 27 – Union-Sarah Lakes Outlet",
                                                            "Project 32 – Polk County Ditch 80 and Laterals 1 & 2",
                                                            "Sand Hill Ditch",
                                                            "Polk Co Ditch 9",
                                                            "Polk Co Ditch 119"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField disabled={is_read_only} field="sur_addinfo" label="Additional Information about Surface Drainage Project" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Culvert Installation / Removal / Modification") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Culvert Installation
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="cul_involves"
                                                            label="Culvert Project involves:"
                                                            options={MAKE_OPTIONS(["removal of existing culverts","installation of new culverts", "modification of existing culverts"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="cul_num"
                                                            label="Number of culverts installed, removed or modified: (minimum 0, maximum 100)"
                                                            fullWidth
                                                            units="count"
                                                            useNumberFormat3
                                                            maxValue={100}
                                                            minValue={0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="cul_info"
                                                            label="Additional Information for Culvert Project"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            disabled={is_read_only}
                                                            field="culroad_involves"
                                                            label="Does Project Involve Road Grading that will modify elevations?"
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="culroad_elev"
                                                            label="If Project modifies road elevations, please describe proposed elevations?"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary"
                                                            style={{marginBottom: 8}}
                                                            onClick={() => {
                                                                this.setState({
                                                                    culvertDialogOpen: true,
                                                                    culvertEditing: null,
                                                                    culvertFormKey: window.performance.now(),
                                                                })
                                                            }}>
                                                            <AddIcon />
                                                            &nbsp;&nbsp;&nbsp;Add New Culvert Information
                                                        </Button>
                                                        <Paper style={{overflowX:"auto"}}>
                                                            <Table>
                                                                <EnhancedTableHead
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    onRequestSort={this.handleRequestSort}
                                                                    columnData={culvertHeader}
                                                                />
                                                                <TableBody>
                                                                    {(() => {
                                                                        if (tempCulverts.length < 1) {
                                                                            return (
                                                                                <TableRow>
                                                                                    <CustomTableCell colSpan={culvertHeader.length} className={classes.centerAlign}>
                                                                                        No Records Available
                                                                                    </CustomTableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }

                                                                        return tempCulverts.map((n) => (
                                                                            <TableRow hover key={ n.id }>
                                                                                <CustomTableCell className={ classes.nowrap }>
                                                                                    <Tooltip title="Edit">
                                                                                        {/* EDIT RECORD will trigger createRepeatableField class*/ }
                                                                                        <Button
                                                                                            onClick={ () => {
                                                                                                this.setState({
                                                                                                    culvertEditing: n,
                                                                                                    culvertDialogOpen:true,
                                                                                                    culvertFormKey: window.performance.now()
                                                                                                });
                                                                                            } }
                                                                                            
                                                                                            className={ classes.deleteWidth }>
                                                                                            <EditIcon color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip>

                                                                                    <Tooltip title="Delete">
                                                                                        <Button
                                                                                            disabled={is_read_only}
                                                                                            onClick={() =>
                                                                                                this.setState({ tempCulverts: tempCulverts.filter((tc) => tc !== n)})
                                                                                            }
                                                                                            className={ classes.deleteWidth }>
                                                                                            <DeleteIcon color="inherit" />
                                                                                            
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </CustomTableCell>
                                                                                {/* PRINTING OF THE DATA IN THE TABLE */ }
                                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                                <CustomTableCell>{n.e_size}</CustomTableCell>
                                                                                <CustomTableCell>{n.e_length}</CustomTableCell>
                                                                                <CustomTableCell>{n.e_elevation}</CustomTableCell>
                                                                                <CustomTableCell>{n.p_size}</CustomTableCell>
                                                                                <CustomTableCell>{n.p_length}</CustomTableCell>
                                                                                <CustomTableCell>{n.p_elevation}</CustomTableCell>
                                                                                <CustomTableCell>{n.additional}</CustomTableCell> 
                                                                            </TableRow>
                                                                        ));
                                                                    })()}
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid>
                                                </Grid> 
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Bridge Installation / Removal / Modification") && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Bridge Installation / Removal / Modification
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            disabled={is_read_only}
                                                            field="brroad_involves"
                                                            label="Does Project Involve Road Grading that will modify elevations?"
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="brroad_elev"
                                                            label="If Project modifies road elevations, please describe proposed elevations?"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="br_involves"
                                                            label="Bridge Project involves:"
                                                            options={MAKE_OPTIONS(["removal of existing bridge","installation of new bridge", "modification of existing bridge"])}
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="br_info"
                                                            label="Additional Information for Bridge Proposed Project"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid> 
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Wetland Restoration / Water Retention Related Structures / Water and Sediment Control Basins") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Wetland Restoration / Water Retention Related Structures / Water and Sediment Control Basins
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="wet_proj"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Project is mitigation for another project"
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wet_desc"
                                                            label="If yes, describe project"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            disabled={is_read_only}
                                                            field="wroad_involves"
                                                            label="Does Project Involve Road Grading that will modify elevations?"
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wroad_elev"
                                                            label="If Project modifies road elevations, please describe proposed elevations?"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="wet_dat"
                                                            label="Datum used for plan development"
                                                            options={MAKE_OPTIONS(["Local","NGVD29", "NAVD88"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wet_op_plan"
                                                            label="Describe operation plan"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wet_dname"
                                                            label="Project Designer Name"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wet_dphone"
                                                            label="Project Designer Phone"
                                                            fullWidth
                                                            usePhoneNumberFormat
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wet_demail"
                                                            label="Project Designer Email"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="wet_info"
                                                            label="Additional Information for Wetland or Water Project"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Road Grading") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Road Grading
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <ReactSelect
                                                            label="Road Grading project involves:"
                                                            field="road_involves"
                                                            options={MAKE_OPTIONS([
                                                                "Roadway elevations will be modified","Project involves removal / installation or modification of culverts",
                                                                "Project involves removal / installation or modification of bridge",
                                                                "Project involves wetland restoration / water retention structures or water and sediment control basins"
                                                            ])}
                                                            disabled={is_read_only}
                                                            isMulti={true}
                                                            height="auto"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="road_info"
                                                            label="Additional Information for Road Grading project"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Dike / Levee") && (  
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Dike / Levee
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="dike_involves"
                                                            label="Dike / Levee Project Involves:"
                                                            options={MAKE_OPTIONS(["New Dike","Modification of Existing Dike" ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="dike_includes"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Project includes construction of ring dike"
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="dike_ring"
                                                            label="Ring dike will tie into:"
                                                            options={MAKE_OPTIONS(["Dike","Roadway", "High Ground", "Other"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_other"
                                                            label="If Other, please describe:"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_purpose"
                                                            label="Describe purpose of proposed dike/levee"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_elevation"
                                                            label="Describe how elevation of dike was determined"
                                                            fullWidth
                                                            useNumberFormat3 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="dike_datum"
                                                            label="Datum used for plan development"
                                                            options={MAKE_OPTIONS(["Local","NGVD29", "NAVD88"])}
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_len"
                                                            label="Dike Length (max 20000)"
                                                            fullWidth
                                                            units="feet"
                                                            useNumberFormat3
                                                            maxValue={20000}
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_twidth"
                                                            label="Top Width (max 50)"
                                                            fullWidth
                                                            units="feet"
                                                            useNumberFormat3
                                                            maxValue={50}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_slopeint"
                                                            label="Side Slopes Interior"
                                                            fullWidth
                                                            units="H:1V"
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_slopeext"
                                                            label="Side Slopes Exterior"
                                                            fullWidth
                                                            units="H:1V"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_maxheight"
                                                            label="Maximum height"
                                                            fullWidth
                                                            units="feet tall"
                                                            useNumberFormat3      
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_maxelev"
                                                            label="Maximum Elevation"
                                                            fullWidth
                                                            units="feet msl"
                                                            useNumberFormat3 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_minheight"
                                                            label="Minimum height"
                                                            fullWidth
                                                            units="feet tall"
                                                            useNumberFormat3 
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_minelev"
                                                            label="Minimum elevation"
                                                            fullWidth
                                                            units="feet msl"
                                                            useNumberFormat3 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="dike_affect"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Dike will flood or adversely affect adjacent, upstream, or downstream landowners"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_impact"
                                                            label="If yes, describe how impacts will be addressed"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="dike_info"
                                                            label= "Additional Information for Dike / Levee project"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}       
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Channel Stabilization / Restoration / Erosion Control / Shoreland Projects") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Channel Stabilization / Restoration / Erosion Control / Shoreland Projects
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <ReactSelect
                                                            label="Channel Stabilization / Restoration / Erosion Control / Shoreland Project Involves:"
                                                            field="ch_involves"
                                                            options={MAKE_OPTIONS([
                                                                "Project includes channel or streambank stabilization",
                                                                "Project includes river or stream restoration",
                                                                "Project lake shore stabilization"
                                                            ])}
                                                            disabled={is_read_only}
                                                            isMulti={true}
                                                            height="auto"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="ch_info"
                                                            label="Additional Information for Channel Stabilization / Restoration / Erosion Control / Shoreland Project"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Land Development") && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Land Development
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="l_prjacres"
                                                            label="Acres of project site:"
                                                            fullWidth
                                                            units="acres"
                                                            useNumberFormat3 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="l_distacres"
                                                            label="Acres of land disturbance"
                                                            fullWidth
                                                            units="acres"
                                                            useNumberFormat3 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="l_stormsewer"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Project is a municipal storm sewer project"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="l_sansewer"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Project is a municipal sanitary sewer or lagoon project"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="l_runoff"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Project design incorporates stormwater ponding or other runoff reduction methods"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="l_explain"
                                                            label="If not yes, explain why:"
                                                            fullWidth 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="l_info"
                                                            label="Additional Information for Land Development Project"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Utility Installations") && (   
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Utility Installation
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <RadioGroup
                                                            field="u_buried"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Utility is buried"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="u_selected"
                                                            label="If yes, what is depth of cover over top of utility? (minimum depth is 48 inches below waterways and ditches)"
                                                            fullWidth
                                                            units="inches"
                                                            useNumberFormat3
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={4}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="u_info"
                                                            label="Additional Information for Utility Installation Project"
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {activeStep === 5 && ( 
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Typography gutterBottom>
                                            Please attached all electronic files associated with your applications. If document is not available for upload, 
                                            it shall be emailed to the SHRWD at <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href='mailto:april.swenby@sandhillwatershed.org'>
                                                Email
                                            </a>. Application will not be reviewed or considered complete until appropriate documents have been submitted. 
                                            Below is the list of expected documents and the information those documents should contain
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now(),
                                                })
                                            }
                                            disabled={is_read_only}
                                            variant="contained"
                                            color="primary">
                                            <AddIcon />
                                            &nbsp;&nbsp;&nbsp;Add Attachment
                                        </Button>
                                        <Paper style={{overflowX:"auto"}}>
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map((n,idx) => {
                                                        return (
                                                            <TableRow key={idx} hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                                })
                                                                            }
                                                                            disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </Grid> 
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Tiling")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                <b>Tiling Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Operating Plan and Site Map. An example of an operating plan can be found <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href='http://www.sandhillwatershed.org/Tile%20operating%20Plan.pdf'>
                                                        here
                                                    </a>  <br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of tile lines.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Size of tile.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Location of outlets(s).
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Location of lift station(s).
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    e.) Location of surface inlet(s).
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    f.) Location of other special features (bioreactors, water level control structures, etc.)
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    g.) Receiving waters.
                                                    <br/>
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    2.) Downstream Landowner Notification, if applicable. 
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Surface")) && ( 
                                    <Grid item xs={12}   >
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Surface Drainage (New Ditch, Improvement, or Maintenance/Cleaning) Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Site Map must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of existing and proposed ditch.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Location of spoil pile.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Location of outlet.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Location of other special features.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    e.) Receiving waters.     <br/>
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                        2.) Downstream Landowner Notification, if applicable. 
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Culvert")) && ( 
                                    <Grid item  xs={12}   >
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Culvert Installation Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Site Map must include:<br/> 
                                                </Typography> 
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of existing and proposed culverts .
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Size of existing and proposed culverts.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Location of upstream and downstream culverts and bridges.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Size of upstream and downstream culverts and bridges.   <br/>
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                        2.) Downstream Landowner Notification, if applicable. 
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Bridge")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Bridge Installation Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Site Map must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of existing and proposed culverts.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Size of existing and proposed culverts.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Location of upstream and downstream culverts and bridges.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Size of upstream and downstream culverts and bridges.
                                                </Typography>
                                        
                                                <Typography variant="subtitle1" gutterBottom>
                                                    2.) Hydraulic analysis showing existing and proposed hydraulic conditions. 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    3.) Size, type, location of adjacent upstream and downstream structures. 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    4.) Maximum previously observed highwater elevation at site and time frame
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    5.) Detailed project construction plans with profile, cross sections, plan views, details. 
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Wetland")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Wetland Restoration Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Detailed project construction plans.<br/> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    2.) Site Map must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of proposed wetland basins, water retention areas.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Locations of ditch plugs, scrapes, embankments, other related features.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Property lines.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Maximum pool elevation boundaries.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    e.) Top of structure elevation.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    f.) Location of proposed tile lines.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    g.) Location of outlet structure(s).
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    h.) Location of other special features.
                                                </Typography>
                                                <Typography style={{ marginLeft: 9 }}>
                                                    i.) Receiving waters.   <br/>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Road")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Road Grading Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Site Map must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of existing and proposed ditch.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Location of spoil pile.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Location of outlet(s).
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Location of existing and proposed culverts and bridges.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    e.) Size of existing and proposed culverts and brides.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    f.) Location of upstream and downstream culverts and bridges.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    g.) Size of upstream and downstream culverts and bridges.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    h.) Location of proposed wetland basins, water retention areas.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    i.) Locations of ditch plugs, scrapes, embankments, other related features.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    j.) Property lines.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    k.) Maximum pool elevation boundaries.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    l.) Location of proposed tile lines.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    m.) Location of other special features.   <br/>
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    n.) Receiving waters.  <br/>
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                        2.) Downstream Landowner Notification, if applicable. 
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Dike")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Dike Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Detailed construction plans.<br/> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    2.) Site Map must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of proposed dike.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Elevation of proposed dike.
                                                </Typography>
                                                <Typography style={{ marginLeft: 9 }}>
                                                    c.) Location of other special features.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Channel")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Channel Stabilization Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Detailed project plans.<br/> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    2.) Site Map, must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of proposed work.
                                                </Typography>
                                                <Typography style={{ marginLeft: 9 }}>
                                                    b.) Location of other special features.
                                                </Typography> 
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Land Dev")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Land Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Detailed construction plans.<br/> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    2.) Site Map, must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of proposed work.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Location of other special features.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Receiving waters.
                                                </Typography>  
                                                <Typography variant="subtitle1" gutterBottom>
                                                    3.) Stormwater Report showing modeling results of pond design.<br/> 
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    4.) Stormwater Pollution Prevention Plan (SWPPP).<br/> 
                                                </Typography> 
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Stormwater")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Stormwater Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Site Map must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of existing and proposed ditch.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Location of spoil pile.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    c.) Location of outlet.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    d.) Location of other special features.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    e.) Receiving waters.     <br/>
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                        2.) Downstream Landowner Notification, if applicable. 
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Utility")) && ( 
                                    <Grid item xs={12}>
                                        <Accordion expanded>
                                            <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Utility Documents to Upload:</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0", display: "block" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    1.) Detailed project plans.<br/> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    2.) Site Map, must include:<br/> 
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    a.) Location of proposed work.
                                                </Typography>
                                                <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                    b.) Location of other special features.
                                                </Typography> 
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {activeStep === 6 && (
                                    <Grid item direction="row"  justifyContent="center" alignItems="center">
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>I understand:</b>
                                        </Typography>
                                        <table>
                                            {" "}
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        1. Submitting this application in no way relieves the applicant or landowner from any 
                                                        responsibility or liability resulting from the construction, operation, or failure of the project.
                                                    </Typography> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        2. The project may be field reviewed prior to the Board Meeting. 
                                                        The applicant grants permission to the SHRWD and their representatives to review the work area within the permit application.
                                                    </Typography> 
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        3. The application must be considered complete a minimum of 10 days prior to the Regular Board
                                                        Meeting to be considered. SHRWD staff will contact you within 15 days if additional information is required.
                                                    </Typography> 
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        4. The requirements of the Permit Rules of the SHRWD. A copy of the rules can be found at <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href='http://www.sandhillwatershed.org/Rules.html'>
                                                            SHRWD Rules
                                                        </a>{" "}
                                                    </Typography> 
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        5. This permit does not relieve the applicant of any requirements of other permits which may be necessary 
                                                        from any other permitting agency.
                                                    </Typography> 
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        6. I acknowledge by submitting this permit application is equivalent to my manual/handwritten signature.
                                                    </Typography> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                        7. I am the landowner for the work proposed in this application or am legally authorized to submit this application on the owner's behalf. Any permit approved under false pretenses will be nullified and considered invalid. No responsibility or liability for such action will and be placed at the SHRWD.
                                                    </Typography> 
                                                </td>
                                            </tr>  
                                        </table>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="understand1" />
                                                </td>
                                                <td>
                                                    <Typography gutterBottom style={{marginBottom:0}}>
                                                        In checking this box, I acknowledge the above statements.
                                                    </Typography> 
                                                </td>
                                            </tr> 
                                        </table>
                                    </Grid>
                                )}
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12}  style={{textAlign:"right"}}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        <Button
                                            onClick={() => this.handleNext()}
                                            disabled={is_read_only && activeStep === 5}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 16 }}>
                                            {activeStep === 6 ? "Submit Permit Application" : "Next"}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS(["Site Map", "Downstream Landownder Notification", "Engineer's Report", "Detailed Construction Plans",
                                                            "Stormwater Report Showing Modeling Results of Pond Design", "Stormwater Pollution Prevention Plan (SWPPP)", "Detailed Project Plans",
                                                            "Photo", "Other"])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={culvertDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Add Culvert
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ culvertDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Typography gutterBottom>
                                Note: If existing culvert is arch pipe or box culvert please include dimensions in Additional Information.
                            </Typography>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                defaultValues={culvertEditing}
                                validateError={this.errorValidatorCulvert}
                                onSubmit={(values) => this.addCulvert(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField disabled={is_read_only}  field="name" label="Culvert Name or ID" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="e_size" label="Existing culvert size" fullWidth units="inches" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="e_length" label="Existing culvert length" fullWidth units="feet" useNumberFormat3 />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="e_elevation" label="Existing culvert elevation" fullWidth units="feet" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="p_size" label="Proposed culvert size" fullWidth units="inches" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="p_length" label="Proposed culvert length" fullWidth units="feet" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="p_elevation" label="Proposed culvert elevation" fullWidth units="feet" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField  disabled={is_read_only} field="additional" label="Additional information about culvert" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <RadioGroup
                                                    disabled={is_read_only}
                                                    field="cul_road_involves"
                                                    label="Does Project Involve Road Grading that will modify elevations?"
                                                    fullWidth
                                                    options={[
                                                        { label: "Yes", value: "Yes" },
                                                        { label: "No", value: "No" },
                                                    ]}
                                                    alignment={true}
                                                />
                                            </Grid> 
                                            <Grid item xs={12} md={6} xl={4}>
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="cul_road_elev"
                                                    label="If Project modifies road elevations, please describe proposed elevations?"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  fullWidth onClick={() => this.setState({ culvertDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  disabled={is_read_only} fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

Sandhill = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...SandhillPermit.actions,
        ...SandhillCulvert.actions,
        ...SandhillAttachment.actions, 
    }
)(Sandhill);

const withMediaQuery = (...args) => Component => props => {
    const mediaQuery = useMediaQuery(...args);
    return <Component mediaQuery={mediaQuery} {...props} />;
};

export default withStyles(styles)(withMediaQuery('(min-width:960px)')(withRouter(Sandhill)));
